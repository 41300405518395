import type { CodeDiscountNodeByCode } from '~/graphql/shopify/queries/codeDiscounts'
import type {
  AutomaticDiscount,
  CustomerGets,
  DiscountType,
  DiscountAutomaticBxgy,
  DiscountAutomaticBasic,
} from '~/graphql/shopify/queries/automaticDiscounts'
import type { CartLine } from '~/graphql/shopify/fragments/cartLines'
import type { AutomaticCodeDiscountWithQualifyingProducts } from '~/stores/shopify'

export interface FreeGiftOption {
  variantId: string
  baseSku: string
  sku: string
}

interface FreeGiftOptions {
  productId: string
  options: Array<FreeGiftOption | null>
}

export interface DiscountSummary {
  discountType: DiscountType
  discountPercentageOff: number
  discountAmountOff: number
  discountIsFree: boolean
  codeDetails?: {
    autoApply: boolean
    code: string
  }
}

interface DiscountsForProduct {
  anyDiscountThresholdPassed: boolean
  anyDiscountIsFree: boolean
  allDiscountOptions: Array<FreeGiftOption & DiscountSummary>
  groupedDiscounts: {
    thresholdSummary: {
      purchaseType: 'DiscountPurchaseAmount' | 'DiscountQuantity'
      amount: number
      thresholdForDiscountMet: boolean
    }
    thresholdForDiscountMet: boolean
    matchedItems: {
      id: string
      sku: string
      price: string
    }[]
    discountSummary: DiscountSummary
    customerGets: {
      productId: string
      options: {
        variantId: string
        baseSku: string
        sku: string
      }[]
    }[]
  }[]
}

export type CartIDRecord = {
  marketId: string
  cartId: string
}

export type CartAttributes = {
  key: string
  value: string
}[]

type BundleItem = {
  node: CartLine
}

export type Bundle = {
  isShopifyBundle: boolean
  bundleId: string
  items: BundleItem[]
  summary: {
    bundleSku?: string
    bundleSkus: string[] | undefined
    bundleBaseSku?: string
    bundleCost: number
    bundleOriginalCost: number
    bundleCostDifference: number
    bundleQuantity: number
    bundleDisplayOrder: number
    bundleCheckoutInfo?: string[]
    bundleDespatchDate?: string
    bundleIsGiftCard: boolean
    bundleIsUpsoldItem?: boolean
    bundleIsPrescription?: boolean
    bundleIsPrebuiltVariant?: boolean
    bundleParentSku?: string
    bundleParentBaseSku?: string
    bundleUsesTheme?: string
  }
}

export type BundledCartItems = {
  [key: string]: Bundle
}

export interface CouponResponse {
  success: boolean
  body?: any
  error?: string
}

export interface GiftCard {
  id?: string
  lastCharacters?: string
  balance?: {
    amount: string
    currencyCode: string
  }
  amountUsed?: {
    amount: string
    currencyCode: string
  }
}

/**
 *
 * @param error
 *
 * Captures error on Sentry and console logs error.
 *
 */
export function captureException(error: Error) {
  const { $sentry } = useNuxtApp()
  // eslint-disable-next-line no-unused-vars
  const sentry = $sentry as { captureException: (error: Error) => void }
  sentry.captureException(error)
  console.error(error)
}

/**
 *
 * @param bundledCartItems
 * @param customisedProduct
 *
 * Finds bundles already in cart where the bundle sku matches the sku of the customisedProduct.sku
 * or customisedProduct.secondarySKU
 *
 */
export function getExistingBundlesInCartFromSku(
  bundledCartItems: BundledCartItems,
  sku: string
) {
  return Object.values(bundledCartItems).filter((value) => {
    return getBundleSkuFromBundleItems(value.items) === sku
  })
}

/**
 *
 * @param productsToRemove
 * @param bundledCartItems
 *
 * Returns an array of Shopify cart item ids based on an array of skus
 *
 */
export function buildLineIdsToRemove(
  productsToRemove: string[],
  bundledCartItems: BundledCartItems
) {
  return (
    productsToRemove
      .map((sku) => {
        const matchedBundleItem = Object.values(bundledCartItems).find(
          (value) => {
            return (
              getBundleSkuFromBundleItems(value.items) === sku ||
              getBundleIsGiftCard(value.items)
            )
          }
        )

        if (matchedBundleItem) {
          if (matchedBundleItem.isShopifyBundle)
            return matchedBundleItem.bundleId
          return matchedBundleItem.items.map((item) => item.node.id)
        }
      })
      .flat() || []
  )
}

export function getParentBundleSku(bundleItems: BundleItem[]) {
  let parentBundleSku = ''

  bundleItems.forEach((bundleItem) => {
    bundleItem.node.attributes.forEach((attr) => {
      if (attr.key === '_belongsToParentCartItemSku' && !parentBundleSku) {
        parentBundleSku = attr.value
      }
    })
  })

  return parentBundleSku
}

export function getUsesTheme(bundleItems: BundleItem[]) {
  let theme = 'default'

  bundleItems.forEach((bundleItem) => {
    bundleItem.node.attributes.forEach((attr) => {
      if (attr.key === '_usesTheme' && !(theme === 'promo')) {
        theme = attr.value
      }
    })
  })

  return theme
}

export function getParentBundleBaseSku(bundleItems: BundleItem[]) {
  let parentBundleBaseSku = ''

  bundleItems.forEach((bundleItem) => {
    bundleItem.node.attributes.forEach((attr) => {
      if (attr.key === '_belongsToParentCartItemBaseSku' && !parentBundleBaseSku) {
        parentBundleBaseSku = attr.value
      }
    })
  })

  return parentBundleBaseSku
}

/**
 *
 * @param newLineItem
 * @param bundledCartItems
 * @param previousBundleId
 *
 * @returns number
 */
function calculateBundleDisplayOrder(
  newLineItem,
  bundledCartItems: BundledCartItems,
  previousBundleId: string
): number {
  const bundleDisplayOrder = Number(
    newLineItem.attributes.find((attr) => attr.key === '_bundleDisplayOrder')
      ?.value ||
      Object.entries(bundledCartItems).length + 1 ||
      0
  )

  const currentBundleId = newLineItem.attributes.find(
    (attr) => attr.key === '_bundleId'
  )?.value


  if (
    previousBundleId !== currentBundleId &&
    Object.entries(bundledCartItems).length === bundleDisplayOrder
  ) {
    return bundleDisplayOrder + 1
  }

  return bundleDisplayOrder
}

/**
 *
 * @param bundledCartItems
 *
 * @param newLineItems
 *
 * Sets the _bundleDisplayOrder attribute on each of the line items based
 * on the number of items in the cart, incrementing the value by one based on
 * the number of bundled items already in the cart.
 *
 * If a cart item already has the _bundleDisplayOrder value it will use that
 * existing value.
 *
 * This is used to persist the order of the cart items when cart items are
 * swapped out for a different product e.g. upgrading a cart item.
 *
 */
export function buildCartItemsWithBundleDisplayOrder(
  bundledCartItems: BundledCartItems,
  newLineItems: any
) {
  let previousLineItemBundleId = ''

  return newLineItems.map((newLineItem: any) => {
    const lineItemWIthBundleDisplayOrder = {
      ...newLineItem,
      attributes: [
        {
          key: '_bundleDisplayOrder',
          value: String(
            calculateBundleDisplayOrder(
              newLineItem,
              bundledCartItems,
              previousLineItemBundleId
            )
          ),
        },
        ...(newLineItem.attributes ? newLineItem.attributes : []),
      ],
    }

    previousLineItemBundleId = newLineItem.attributes.find(
      (attr: any) => attr.key === '_bundleId'
    )?.value

    return lineItemWIthBundleDisplayOrder
  })
}

/**
 *
 * @param lines
 *
 * Loops through each line item creates an array of those bundle ids and then
 * deduplicates them via Set()
 *
 */
export function getUniqueBundleIdsFromNewLineItems(lines): Array<string> {
  const bundleIds: string[] = []

  lines.forEach((line) => {
    return line.attributes.forEach((attr) => {
      if (attr.key === '_bundleId') {
        bundleIds.push(attr.value)
      }
    })
  })

  return [...new Set(bundleIds)] as string[]
}

/**
 *
 * @param bundleItems
 *
 * Finds the bundle display order value based on the first line item inside a bundle
 * that has the _bundleDisplayOrder attribute set.
 *
 */
export function getBundleDisplayOrder(bundleItems: BundleItem[]) {
  let displayOrder = 0

  bundleItems.forEach((bundleItem) => {
    bundleItem.node.attributes.forEach((attr) => {
      if (attr.key === '_bundleDisplayOrder' && !displayOrder) {
        displayOrder = Number(attr.value)
      }
    })
  })

  return displayOrder
}

/**
 *
 * @param bundleItems
 *
 * Finds the bundle sku based on the first line item inside a bundle that has the
 * _bundleSku value set.
 *
 */
export function getBundleSkuFromBundleItems(bundleItems: BundleItem[]) {
  let bundleSku: string | undefined

  bundleItems.forEach((bundleItem) => {
    bundleItem.node.attributes.forEach((attr) => {
      if (attr.key === '_bundleSku') {
        bundleSku = attr.value
      }
    })
  })

  // Fallback to using the SKU of the first item in the bundle
  if (!bundleSku) {
    bundleSku =
      bundleItems[0].node.merchandise.sku ||
      bundleItems[0].node.merchandise.product?.handle // Required for gift cards
  }

  return bundleSku
}

/**
 *
 * @param bundleItems
 *
 * Find the checkout info from the bundle item that has the
 * _checkoutInfo value set.
 *
 */
export function getBundleCheckoutInfo(bundleItems: BundleItem[]) {
  let checkoutInfo

  bundleItems.forEach((bundleItem) => {
    bundleItem.node.attributes?.forEach((attr) => {
      if (attr.key === '_checkoutInfo') {
        checkoutInfo = attr.value.split(',')
      }
    })
  })

  return checkoutInfo
}

/**
 *
 * @param bundleItems
 *
 * Find the checkout info from the bundle item that has the
 * _despatchDate value set.
 *
 */
export function getBundleDespatchDate(bundleItems: BundleItem[]) {
  let despatchDate

  bundleItems.forEach((bundleItem) => {
    bundleItem.node.attributes.forEach((attr) => {
      if (attr.key === '_despatchDate') {
        despatchDate = attr.value
      }
    })
  })

  return despatchDate
}

export function getBundleIsGiftCard(bundleItems: BundleItem[]) {
  return bundleItems.some(
    (bundleItem) => bundleItem.node.merchandise.product?.isGiftCard
  )
}

/**
 *
 * @param bundleItems
 *
 * Find the checkout info from the bundle item that has the
 * _upsellProduct value set.
 *
 */
export function getBundleUpsellItem(bundleItems: BundleItem[]) {
  let isBundleUpsellItem

  bundleItems.forEach((bundleItem) => {
    bundleItem.node.attributes.forEach((attr) => {
      if (attr.key === '_isUpsellItem') {
        isBundleUpsellItem = attr.value
      }
    })
  })

  return !!isBundleUpsellItem
}

/**
 *
 * @param bundleItems
 *
 * Find the checkout info from the bundle item that has the
 * isPrescription in the _checkoutInfo value.
 *
 */
export function getBundleIsPrescription(bundleItems: BundleItem[]) {
  let isPrescription

  bundleItems.forEach((bundleItem) => {
    bundleItem.node.attributes.forEach((attr) => {
      if (attr.key === '_checkoutInfo') {
        isPrescription = attr.value.includes('isPrescription')
      }
    })
  })

  return !!isPrescription
}

/**
 *
 * @param bundleItems
 *
 * Returns an array of part skus used to create the bundleSku
 *
 */
export function getBundleSkusFromBundleItems(
  bundleItems: BundleItem[]
): string[] {
  return (
    bundleItems
      .map((bundleItem) => {
        return (bundleItem.node.merchandise.sku ||
          bundleItem.node.merchandise.product?.handle) as string
      })
      .filter(Boolean) || []
  )
}

/**
 *
 * @param bundleItems
 *
 * Finds the bundle base sku based on the first line item with the _bundleSku
 * attribute which then pulls the base sku from the merchanise.sku field from the
 * Shopify response.
 *
 */
export function getBundleBaseSkuFromBundleItems(bundleItems: BundleItem[]) {
  let bundleBaseSku: string | undefined

  bundleItems.forEach((bundleItem) => {
    if (bundleItem.node.lineComponents?.length) {
      bundleBaseSku = bundleItem.node.merchandise.sku as string
    } else if (!bundleBaseSku) {
      bundleItem.node.attributes.forEach((attr) => {
        if (attr.key === '_bundleSku') {
          bundleBaseSku = bundleItem.node.merchandise.sku as string
        }

        if (attr.key === '_prebuiltSku') {
          bundleBaseSku = attr.value.split('-')[0]
        }
      })
    }
  })

  if (!bundleBaseSku) {
    // Fallback to using the SKU of the first item in the bundle
    bundleBaseSku = bundleItems[0].node.merchandise.sku
  }

  if (bundleBaseSku?.startsWith('egiftcard')) bundleBaseSku = 'egiftcard'

  return bundleBaseSku
}

/**
 *
 * @param bundleItems
 *
 * Calculates the bundle cost by combining the cost of each line item in the bundle
 *
 */
export function getBundleCost(bundle: Bundle) {
  // For Shopify bundles just return the cost of the parent line item - the one which has lineComponents
  if (bundle.isShopifyBundle)
    return Number(
      bundle.items.find(
        ({ node: { lineComponents } }) => lineComponents?.length
      )?.node.cost.totalAmount.amount || 0
    )

  // For custom bundles calculate the cost of all items in the bundle
  let bundleCost = 0

  bundle.items.forEach((bundleItem) => {
    bundleCost += Number(bundleItem.node.cost.totalAmount.amount)
  })

  return bundleCost
}

/**
 *
 * @param bundle
 *
 * Calculates the original bundle cost without any discounts
 *
 */
export function getOriginalBundleCost(bundle: Bundle) {
  // For Shopify bundles just return the cost of the parent line item - the one which has lineComponents)
  if (bundle.isShopifyBundle)
    return Number(
      bundle.items.find(
        ({ node: { lineComponents } }) => lineComponents?.length
      )?.node.cost.subtotalAmount.amount || 0
    )

  // For custom bundles calculate the cost of all items in the bundle
  let originalBundleCost = 0

  bundle.items.forEach((bundleItem) => {
    originalBundleCost += Number(bundleItem.node.cost.subtotalAmount.amount)
  })

  return originalBundleCost
}

/**
 *
 * @param bundleItems
 *
 * Calculates the bundle quantity by finding items with the _bundleSku attribute and
 * returning the quantity field value from the Shopify response.
 *
 */
export function getBundleQuantity(bundleItems: BundleItem[]) {
  let bundleQuantity = 0

  bundleItems.forEach((bundleItem) => {
    bundleItem.node.attributes.forEach((attr) => {
      if (attr.key === '_bundleSku') {
        bundleQuantity = bundleItem.node.quantity
      }
    })
  })

  // Fallback to using the quantity of the first item in the bundle
  if (!bundleQuantity) {
    bundleQuantity = bundleItems[0].node.quantity
  }

  return bundleQuantity
}

export function getExpectedCartAttributes(): CartAttributes {
  const cartAttributes = [] as CartAttributes

  // Get Amplitude device ID from the amplitude plugin
  const { $amplitude } = useNuxtApp()
  const amplitudeDevideId = (
    $amplitude as { getDeviceId: () => string }
  )?.getDeviceId()
  if (amplitudeDevideId) {
    cartAttributes.push({
      key: '_amplitudeDeviceId',
      value: amplitudeDevideId,
    })
  }

  // Get Avelon Network avln_cid cookie
  const avelonCookie = useCookie('avln_cid')
  if (avelonCookie.value) {
    cartAttributes.push({
      key: '_avln_cid',
      value: avelonCookie.value,
    })
  }

  // Get Google Click ID (gclid) from store or URL
  const userStore = useUserStore()
  const route = useRoute()
  const gclid = route.query.gclid || userStore.gclid
  if (gclid) {
    cartAttributes.push({
      key: '_gclid',
      value: gclid as string
    })
  }

  return cartAttributes
}

export function buildCartItems(
  customisedProduct: any,
  metaData: CartAttributes = [],
  quantity: number = 1
) {
  const bundleId = Math.random().toString(36).substring(7)

  const baseVariantId = customisedProduct.product.commerceId

  // Check if we are selling a prebuilt variant or a customised product
  const isPrebuiltVariant = customisedProduct.isPrebuiltVariant
  const prebuiltVariant = isPrebuiltVariant && customisedProduct.matchingPrebuiltVariant

  const cartItemCommerceOptions = [] as {
    type?: string
    commerceId: string
  }[]

  const despatchDate =
    customisedProduct.delayedDespatchDate ||
    (customisedProduct.isOnPreorder && customisedProduct.preorderDate) ||
    null

  const checkoutInfo = [
    !isPrebuiltVariant && customisedProduct.product?.showPackagingOptions && 'litePackagingAvailable',
    !isPrebuiltVariant && customisedProduct.product?.defaultPackagingOption === 'lite' &&
      'defaultLitePackaging',
    customisedProduct.isPrescription && 'isPrescription',
    customisedProduct.isOnPreorder &&
      customisedProduct.preorderDate &&
      'isPreorder',
    customisedProduct.delayedDespatchDate && 'isDelayedDespatch',
  ]
    .filter((item) => item)
    ?.join(',')

  /**
   *
   * If the cart transforms function is disabled on Shopify you must
   * uncomment these lines.
   *
   **/

  // cartItemCommerceOptions.push({
  //   type: 'product',
  //   commerceId: customisedProduct.product.commerceId,
  // })

  if (!isPrebuiltVariant) {
    Object.values(customisedProduct.choices).forEach((choice) => {
      if (choice.commerceId) {
        cartItemCommerceOptions.push({
          type: 'variant',
          commerceId: choice.commerceId,
        })
      }
    })
  }

  // If there are no choices then the product is a limited edition/simple product
  // and we should use the product id as the commerce id
  let isBundle = true
  if (isPrebuiltVariant) {
    isBundle = false
    cartItemCommerceOptions.push({
      type: 'product',
      commerceId: prebuiltVariant.commerceId,
    })
  } else if (!cartItemCommerceOptions.length) {
    isBundle = false
    cartItemCommerceOptions.push({
      type: 'product',
      commerceId: customisedProduct.product.commerceId,
    })
  }

  return cartItemCommerceOptions.map(({ commerceId }, index) => {
    const attributes = [] as CartAttributes

    if (metaData) {
      metaData.forEach((item) => attributes.push(item))
    }

    if (isBundle) {
      attributes.push({
        key: '_bundleId',
        value: bundleId,
      })

      // We want to add more detailed attributes to the first item in the bundle
      if (index === 0) {
        // Used by `sg-shopify-external` Cart Transform to determine what
        // variant should be used to create the bundle
        attributes.push({
          key: '_baseVariantId',
          value: baseVariantId,
        })

        attributes.push({
          key: '_bundleSku',
          value: customisedProduct.sku,
        })
      }
    }

    if (index === 0) {
      // Used by `sg-shopify-external` checkout UI extensions
      if (checkoutInfo)
        attributes.push({
          key: '_checkoutInfo',
          value: checkoutInfo,
        })
      if (despatchDate)
        attributes.push({
          key: '_despatchDate',
          value: despatchDate,
        })

      // Add metadata only to the first item in the bundle
      attributes.concat(metaData)
    }

    if (isPrebuiltVariant) {
      // Store that this is a prebuilt variant and include the full SKU
      attributes.push({
        key: '_prebuiltSku',
        value: customisedProduct.matchingPrebuiltVariant.customSKU
      })
    }

    if (customisedProduct.state.primarySkuAddedToCart?.sku) {
      attributes.push({
        key: '_belongsToParentCartItemSku',
        value: customisedProduct.state.primarySkuAddedToCart.sku
      })

      if (customisedProduct.state.discountSummary.specialTheme) {
        attributes.push({
          key: '_usesTheme',
          value: 'promo'
        })
      }

      attributes.push({
        key: '_belongsToParentCartItemBaseSku',
        value: customisedProduct.state.primarySkuAddedToCart.baseSku
      })
    }

    return {
      merchandiseId: commerceId,
      quantity: quantity,
      attributes: attributes.filter((a) => a),
    }
  })
}

export async function getCouponCode(
  code: string
): Promise<CodeDiscountNodeByCode | null> {
  try {
    const response = await useFetch<CodeDiscountNodeByCode>(
      `/api/discounts/code/${code}`
    )
    const coupon = response?.data?.value || null
    if (coupon?.id) {
      return coupon
    } else {
      return null
    }
  } catch (error: any) {
    captureException(error)
  }
  return null
}

export async function isValidGiftCard(code: string): Promise<boolean> {
  const response = await useFetch<any>(`/api/giftcards/code/${code}`)

  return response?.data?.value
}

/**
 * @param customerGets
 */
function buildDiscountSummary(customerGets: CustomerGets): DiscountSummary {
  return {
    discountType: customerGets.value.effect ? customerGets.value.effect.type : customerGets.value.type,
    discountPercentageOff: Number(customerGets.value.effect ? (customerGets.value.effect.percentage || 0) : customerGets.value.percentage || 0),
    discountAmountOff: Number(customerGets.value.effect ? customerGets.value.effect.amount?.amount || 0 : customerGets.value.amount?.amount),
    discountIsFree: customerGets.value.effect ? customerGets.value.effect.percentage === 1 : Number(customerGets.value.percentage) === 1,
  }
}

/**
 * @param customerGets
 */
function buildFreeGiftOptions(
  customerGets: CustomerGets
): Array<FreeGiftOptions | []> {
  const discountedOptions: Array<FreeGiftOptions> = []

  if (customerGets.items.type === 'DiscountProducts') {
    if (customerGets.items.products.nodes.length) {
      discountedOptions.push(
        ...customerGets.items.products.nodes.map((node) => ({
          productId: node.id,
          options: node.variants.nodes
            .map((variant) => {
              return {
                variantId: variant.id,
                baseSku: buildFreeGiftBaseSku(variant.sku),
                sku: buildFreeGiftVariantSku(variant.sku),
              }
            })
            .filter((a) => a),
        }))
      )
    }

    if (customerGets.items.productVariants.nodes.length) {
      type Accumulator = {
        [productId: string]: {
          id: string
          sku: string
          product: { id: string }
        }[]
      }

      const groupedVariants: Accumulator =
        customerGets.items.productVariants.nodes.reduce(
          (acc: Accumulator, curr): Accumulator => {
            (acc[curr.product.id] = acc[curr.product.id] || []).push(curr)
            return acc
          },
          {}
        )

      discountedOptions.push(
        ...Object.entries(groupedVariants).map(([productId, variants]) => {
          return {
            productId,
            options: variants.map((variant) => ({
              variantId: variant.id,
              baseSku: buildFreeGiftBaseSku(variant.sku),
              sku: buildFreeGiftVariantSku(variant.sku),
            })),
          }
        })
      )
    }

    return discountedOptions.filter(
      (discountOption) => discountOption.options.length
    )
  }

  else if (customerGets.items.type === 'DiscountCollections') {
    discountedOptions.push(
      ...customerGets.items.collections.nodes.map((node) => ({
        productId: node.id,
        options: node.products.nodes
          .flatMap((productNode) => {
            return productNode.variants.nodes.flatMap((variant) => {
              const baseSku = buildFreeGiftBaseSku(variant.sku)

              if ('case' === baseSku) return null
  
              return {
                variantId: variant.id,
                baseSku: buildFreeGiftBaseSku(variant.sku),
                sku: buildFreeGiftVariantSku(variant.sku),
              }
            })
          })
          .filter((a) => a),
      }))
    )

    return discountedOptions.filter((a) => a)
  }

  return []
}

function buildFreeGiftVariantSku(sku: string) {
  /**
   *
   * Case products are uniquely set up requiring two separate skus
   * to form a tangible product. When the ct product is selected we
   * must manually prefix 'case' here as it is not possible to find this
   * information through Shopify as the 'ct' product and 'case' are
   * held separately and unrelated to one another. The addition of more
   * metadata attached to the product could address this in the future.
   *
   */
  return sku
}

function buildFreeGiftBaseSku(sku: string) {
  if (sku.includes('ct_')) {
    return 'case'
  } else if (sku.includes('-le_')) {
    return sku
  } else if (sku.includes('_')) {
    return sku.split('_')[0]
  }

  return sku
}

export function getAllValidGiftDiscounts(
  automaticDiscounts: AutomaticDiscount[],
  automaticCodeDiscounts: AutomaticCodeDiscountWithQualifyingProducts[]
): DiscountAutomaticBxgy[] | DiscountAutomaticBasic[] | AutomaticCodeDiscountWithQualifyingProducts[] | [] {
  const matchedAutomaticDiscount = automaticDiscounts.filter(
    (discount: AutomaticDiscount) => {
      if (discount.automaticDiscount.automaticDiscountType === 'DiscountAutomaticBxgy') {
        if (discount.automaticDiscount.discountClass === 'PRODUCT') {
          // The products a customer gets are part of a manually defined product list within the discount
          if (
            discount.automaticDiscount.customerGets?.items?.type ===
            'DiscountProducts'
          ) {
            return (
              !!discount.automaticDiscount.customerGets.items.products.nodes
                .length ||
              !!discount.automaticDiscount.customerGets.items.productVariants
                .nodes.length
            )
          }

          // If it is a collection of items a customer gets
          if (
            discount.automaticDiscount.customerGets?.items?.type ===
            'DiscountCollections'
          ) {
            // Keep the discount if the collection has a non-zero array of products defined in the collection
            return !!discount.automaticDiscount.customerGets.items.collections
              ?.nodes.length
          }

          // The items a customer gets have not been defined therefore nothing qualifies for the promo and can be not returned
          return false
        }
      } else if (discount.automaticDiscount.automaticDiscountType === 'DiscountAutomaticBasic') {
        /**
         * if it's a price threshold that needs to be passed and it
         * has a value set then return true as a valid discount. We'll assess in
         * downstream functions if the threshold has been met or not. This just assesses it's valid.
         * */

        if (discount.automaticDiscount.minimumRequirement?.greaterThanOrEqualToSubtotal?.amount) {
          return true
        }
      }

      return false
    }
  ) as DiscountAutomaticBxgy[] | DiscountAutomaticBasic[] | []

  const matchedAutomaticCodeDiscounts = automaticCodeDiscounts.filter(
    (discount: AutomaticCodeDiscountWithQualifyingProducts) => {
      if (discount.codeDiscount.automaticDiscountType === 'DiscountCodeBasic') {
        /**
         * In the case of DiscountCodeBasic we alway want to return true.
         *
         * We'll assess in downstream functions if the threshold has been met or
         * not. This just assesses it's valid.
         * */
        return true
      } else {
        console.warn('Types other than DiscountCodeBasic for auto applying discount codes are not yet supported.')
      }

      return false
    }
  ) as AutomaticCodeDiscountWithQualifyingProducts[] | []

  return [ ...matchedAutomaticDiscount, ...matchedAutomaticCodeDiscounts ]
}

function getQualifyingProductForDiscount(
  validGiftDiscount: DiscountAutomaticBxgy | DiscountAutomaticBasic | AutomaticCodeDiscountWithQualifyingProducts,
  customisedProduct: any | null,
  subject = 'customerBuys',
  staticProductData?: {
    sku: string,
    price: number
  }
) {
  let matchedItem

  if('automaticDiscount' in validGiftDiscount) {
    if (validGiftDiscount.automaticDiscount.automaticDiscountType === 'DiscountAutomaticBasic') {
      if (validGiftDiscount.automaticDiscount.minimumRequirement?.type === 'DiscountMinimumSubtotal') {
        console.warn('Calling getQualifyingProductForDiscount on a DiscountAutomaticBasic with a DiscountMinimumSubtotal requirement type is not REQUIRED.')
        return
      } else if (validGiftDiscount.automaticDiscount.minimumRequirement?.type === 'DiscountMinimumQuantity') {
        console.warn('Calling getQualifyingProductForDiscount on a DiscountAutomaticBasic with a DiscountMinimumQuantity requirement type is not currently SUPPORTED.')
        return
      }
    }
    else if (validGiftDiscount.automaticDiscount.automaticDiscountType === 'DiscountAutomaticBxgy') {
      if (
        validGiftDiscount.automaticDiscount[subject].items.type ===
        'DiscountCollections'
      ) {
        validGiftDiscount.automaticDiscount[
          subject
        ].items.collections?.nodes.forEach((collectionItem) => {
          return collectionItem.products.nodes.forEach((collectionProductItem) => {
            collectionProductItem.variants.nodes.forEach(
              (collectionProductItemVariant) => {
                if (staticProductData && !customisedProduct) {
                  if (staticProductData.sku.includes('-le')) {
                    if (staticProductData.sku === collectionProductItemVariant.sku) {
                      matchedItem = collectionProductItemVariant
                      matchedItem.price = Number(staticProductData.price)
                    }
                  } else {
                    const staticProductDataBaseSku = staticProductData.sku.split('-')[0]

                    if (collectionProductItemVariant.sku.includes(staticProductDataBaseSku) && !collectionProductItemVariant.sku.includes('-le')) {
                      matchedItem = collectionProductItemVariant
                      matchedItem.price = Number(staticProductData.price)
                    }
                  }
                } else if (customisedProduct) {
                  if (customisedProduct.isLimitedEdition) {
                    if (
                      customisedProduct.product.commerceId ===
                      collectionProductItemVariant.id
                    ) {
                      matchedItem = collectionProductItemVariant
                      matchedItem.price = customisedProduct.product.priceGBP || customisedProduct.priceGBP
                    }
                  } else if (customisedProduct.isPrebuiltVariant) {
                    if (
                      customisedProduct.matchingPrebuiltVariant?.commerceId === collectionProductItemVariant.id
                    ) {
                      matchedItem = collectionProductItemVariant
                      matchedItem.price = customisedProduct.product.priceGBP || customisedProduct.priceGBP
                    }
                  } else if (customisedProduct.product.commerceId === collectionProductItemVariant.id) {
                    matchedItem = collectionProductItemVariant
                    matchedItem.price = customisedProduct.product.priceGBP || customisedProduct.priceGBP
                  }

                  if (!matchedItem) {
                    Object.values(customisedProduct.choices).forEach(
                      (choice: any) => {
                        if (choice.commerceId === collectionProductItemVariant.id) {
                          matchedItem = collectionProductItemVariant
                          matchedItem = choice.priceGBP
                        }
                      }
                    )
                  }
                }
              }
            )
          })
        })
      } else if (
        validGiftDiscount.automaticDiscount[subject].items.type ===
        'DiscountProducts'
      ) {
        // if all variants for a product are selected then use the products field
        if (
          validGiftDiscount.automaticDiscount[subject].items.products?.nodes.length
        ) {
          validGiftDiscount.automaticDiscount[subject].items.products.nodes.forEach(
            (product) => {
              product.variants.nodes.forEach((productVariant) => {
                /**
                 *
                 * If only one variant is selected as the thing a customer
                 * must buy or the product selected is a limited edition then treat it as any
                 * version of the base product selected qualifies for the free gift.
                 *
                 * e.g. renegades, renegades-le_*
                 *
                 **/

                if (staticProductData && !customisedProduct) {
                  if (staticProductData.sku.includes('-le')) {
                    if (staticProductData.sku === productVariant.sku) {
                      matchedItem = productVariant
                      matchedItem.price = Number(staticProductData.price)
                    }
                  } else {
                    const staticProductDataBaseSku = staticProductData.sku.split('-')[0]

                    if (productVariant.sku.includes(staticProductDataBaseSku) && !productVariant.sku.includes('-le')) {
                      matchedItem = productVariant
                      matchedItem.price = Number(staticProductData.price)
                    }
                  }
                } else if (customisedProduct) {
                  if (customisedProduct.isLimitedEdition) {
                    if (customisedProduct.product.commerceId === productVariant.id) {
                      matchedItem = productVariant
                      matchedItem.price = customisedProduct.product.priceGBP || customisedProduct.priceGBP
                    }
                  } else if (customisedProduct.isPrebuiltVariant) {
                    if (
                      customisedProduct.matchingPrebuiltVariant?.commerceId === productVariant.id
                    ) {
                      matchedItem = productVariant
                      matchedItem.price = customisedProduct.product.priceGBP || customisedProduct.priceGBP
                    }
                  } else if (customisedProduct.product.commerceId === productVariant.id) {
                    matchedItem = productVariant
                    matchedItem.price = customisedProduct.product.priceGBP || customisedProduct.priceGBP
                  }

                  if(!matchedItem) {
                    Object.values(customisedProduct.choices).forEach(
                      (choice: any) => {
                        if (choice.commerceId === productVariant.id) {
                          matchedItem = productVariant
                          matchedItem.price = choice.priceGBP
                        }
                      }
                    )
                  }
                }
              })
            }
          )
        }

        // else only some variants for a products are selected then use the productVariants field
        if (
          validGiftDiscount.automaticDiscount[subject].items.productVariants?.nodes
            .length
        ) {
          validGiftDiscount.automaticDiscount[
            subject
          ].items.productVariants.nodes.forEach((productVariant) => {
            if (staticProductData && !customisedProduct) {
              if (staticProductData.sku.includes('-le')) {
                if (staticProductData.sku === productVariant.sku) {
                  matchedItem = productVariant
                  matchedItem.price = Number(staticProductData.price)
                }
              } else {
                const staticProductDataBaseSku = staticProductData.sku.split('-')[0]

                if (productVariant.sku.includes(staticProductDataBaseSku) && !productVariant.sku.includes('-le')) {
                  matchedItem = productVariant
                  matchedItem.price = Number(staticProductData.price)
                }
              }
            } else if(customisedProduct) {
              Object.values(customisedProduct.choices).forEach((choice: any) => {
                if (choice.commerceId === productVariant.id) {
                  matchedItem = productVariant
                  matchedItem.price = choice.priceGBP
                }
              })

              /**
               *
               * After assessing each individual choice also assess if the customised product commerceId assigned
               * to the base product matches on any of the productVariants in the list.
               *
               */
              if (customisedProduct.product.commerceId === productVariant.id) {
                matchedItem = productVariant
                matchedItem.price = customisedProduct.product.priceGBP || customisedProduct.priceGBP
              }
            }
          })
        }
      }
    }
  } else if ('codeDiscount' in validGiftDiscount) {
    if (validGiftDiscount.codeDiscount.minimumRequirement?.type === 'DiscountMinimumSubtotal') {
      console.warn('Calling getQualifyingProductForDiscount on a DiscountCodeBasic with a DiscountMinimumSubtotal requirement type is not REQUIRED.')
      return
    } else if (validGiftDiscount.codeDiscount.minimumRequirement?.type === 'DiscountMinimumQuantity') {
      console.warn('Calling getQualifyingProductForDiscount on a DiscountCodeBasic with a DiscountMinimumQuantity requirement type is not currently SUPPORTED.')
      return
    }
  }

  return matchedItem
}

/**
 *
 * @param groupedDiscounts
 *
 * Returns all available discount options (customerGets) across
 * all groupedDiscounts.
 *
 */
function getAllDiscountOptions(groupedDiscounts) {
  return groupedDiscounts?.flatMap((discount) => {
    return discount.customerGets?.flatMap((customerGet) => {
      return customerGet.options?.map((option) => {
        return {
          discountSummary: discount.discountSummary,
          thresholdSummary: discount.thresholdSummary,
          ...option,
        }
      })
    })
  })
}

function getAllDiscountsForProduct(
  customisedProduct: any,
  allValidGiftDiscounts: DiscountAutomaticBasic[] | DiscountAutomaticBxgy[] | AutomaticCodeDiscountWithQualifyingProducts[]
): DiscountsForProduct | object {
  return allValidGiftDiscounts
    .map((validGiftDiscount) => {
      if ('automaticDiscount' in validGiftDiscount) {
        if (validGiftDiscount.automaticDiscount.automaticDiscountType === 'DiscountAutomaticBasic') {
          if (validGiftDiscount.automaticDiscount.minimumRequirement?.type === 'DiscountMinimumSubtotal') {
            /**
             * In the case of a basic discount with a price threshold we can just return the discount for
             * the product as this discount type is not dependent on the specific product the customer is buying
             * itself, just the overall spend.
             */
            return validGiftDiscount
          }
        }
      } else if ('codeDiscount' in validGiftDiscount) {
        if (validGiftDiscount.codeDiscount.automaticDiscountType === 'DiscountCodeBasic') {
          /**
           * In the case of a DiscountCodeBasic we can just return the discount for
           * the product as this discount type is not dependent on the specific product
           * the customer is buying itself.
           */
          return validGiftDiscount
        }
      }

      const matchedItems: Array<{
        id: string
        sku: string
        price: string
      }> = []

      const newMatchedItem = getQualifyingProductForDiscount(
        validGiftDiscount,
        customisedProduct,
        'customerGets'
      )

      if (newMatchedItem) {
        matchedItems.push(newMatchedItem)
      }

      /**
       * If there are matching discounts for the product then return the valid git discount
       * that qualifies for the product.
       */
      if (matchedItems.length) {
        return validGiftDiscount
      }
    })
    .filter((a) => a)
}


export function getDiscountsFromCartItems({
  customisedProduct = null,
  bundledCartItems = {},
  automaticDiscounts = [],
  automaticCodeDiscounts = [],
  staticProductData = null
}: {
  customisedProduct?: any,
  bundledCartItems: { [key: string]: Bundle },
  automaticDiscounts: AutomaticDiscount[],
  automaticCodeDiscounts: AutomaticCodeDiscountWithQualifyingProducts[],
  cartSubtotal?: number,
  staticProductData?: {
    sku: string
  } | null,
}) {
  /**
   *
   * The getDiscountsFromCartItems function allows you to tell if a particular product qualifies for
   * a discount based on the items already in the cart or the subtotal.
   *
   *
   * This is different from the getAllDiscountsFromProduct function which allows you to find out if adding
   * that product would mean you qualify for a discount on something else.
   *
   * e.g. Add Renegades -> Get Free Capsule Case
   *
   *
   * The customisedProduct parameter in this case is the product itself which you want to know if it qualifies
   * for a discount e.g. a Capsule Case.
   *
   */

  const allProductIdsInCart: string[] = []

  Object.values(bundledCartItems).forEach((bundledItem) => {
    bundledItem.items.forEach((item) => {
      if (item.node.merchandise.id) {
        allProductIdsInCart.push(item.node.merchandise.id)
      }
    })
  })

  const discountsForProduct = getAllDiscountsForProduct(
    customisedProduct,
    getAllValidGiftDiscounts(automaticDiscounts, automaticCodeDiscounts)
  ) as DiscountAutomaticBxgy[] | DiscountAutomaticBasic[] | AutomaticCodeDiscountWithQualifyingProducts[]

  const discountsQualifyBasedOnCartItems: DiscountAutomaticBxgy[] | DiscountAutomaticBasic[] | AutomaticCodeDiscountWithQualifyingProducts[] = Object.values(
    discountsForProduct
  ).filter((discount: DiscountAutomaticBxgy | DiscountAutomaticBasic | AutomaticCodeDiscountWithQualifyingProducts) => {
    if ('automaticDiscount' in discount) {
      if (discount.automaticDiscount.automaticDiscountType === 'DiscountAutomaticBasic') {
        /**
         *
         * If it's a basic discount with a price threshold then we need to check if the price
         * on the cart matches or surpasses the threshold set by the discount.
         *
         */
        if (discount.automaticDiscount.minimumRequirement?.greaterThanOrEqualToSubtotal?.amount && cartSubtotal) {
          return cartSubtotal >= Number(discount.automaticDiscount.minimumRequirement.greaterThanOrEqualToSubtotal.amount)
        }

        else if (discount.automaticDiscount.minimumRequirement?.greaterThanOrEqualToQuantity) {
          console.warn('Item quantity thresholds on DiscountAutomaticBasic are not yet SUPPORTED')
          return false
        }
      }

      else if (discount.automaticDiscount.automaticDiscountType === 'DiscountAutomaticBxgy' && discount.automaticDiscount.customerBuys.items.collections) {
        return discount.automaticDiscount.customerBuys.items.collections.nodes.filter((collectionNode) => {
          return collectionNode.products.nodes.filter((productNode) => {
            return productNode.variants.nodes.filter(variantNode => {
              return allProductIdsInCart.find((productId) => productId === variantNode.id)
            }).length
          }).length
        }).length
      } else if(discount.automaticDiscount.automaticDiscountType === 'DiscountAutomaticBxgy') {
        return discount.automaticDiscount.customerBuys.items.products?.nodes?.filter(
          (node) =>
            node.variants.nodes.filter((variantNode) =>
              allProductIdsInCart.find((productId) => productId === variantNode.id)
            ).length
        ).length
      }
    } else if ('codeDiscount' in discount) {
      /**
       *
       * Check in here if the code discount has a base product sku for any cart item
       * bundleBaseSku property in it's base products sku array.
       *
       * 1. Get all base product skus from the existing cart items as an array.
       *
       * 2. Check if the code is applicable to this product by checking the array for any matching baseSku.
       *
       * 3. If there is no match return early as this discount code can't be used for this product based
       * on the items in the cart.
       *
       **/
      if (discount.qualifyingBaseProducts?.length) {
        const baseSkusFromCartItems = Object.values(bundledCartItems).map((bundledItem) => {
          return bundledItem.summary.bundleBaseSku
        })

        const discountHasQualifyingCartItem = discount.qualifyingBaseProducts.some(qualifyingBaseProduct => {
          return baseSkusFromCartItems.includes(qualifyingBaseProduct)
        })

        if (!discountHasQualifyingCartItem) return
      }

      if (discount.codeDiscount.automaticDiscountType === 'DiscountCodeBasic') {
        if(!discount.codeDiscount.minimumRequirement) {
          return true
        } else {
          console.warn('Auto applied discount codes based on cart items with a minimum requirement is not currently supported.')
          return false
        }
      }
    }
  })

  const builtDiscounts = discountsQualifyBasedOnCartItems.map((discount) => {
    const matchedItems: Array<{
      id: string
      sku: string
      price: string
    }> = []
    let thresholdForDiscountMet

    if ('automaticDiscount' in discount) {
      if (discount.automaticDiscount.automaticDiscountType === 'DiscountAutomaticBasic') {
        return {
          matchedItems,
          thresholdForDiscountMet,
          discountSummary: buildDiscountSummary(
            discount.automaticDiscount.customerGets
          ),
          customerGets: buildFreeGiftOptions(
            discount.automaticDiscount.customerGets
          ),
        }
      } else if (discount.automaticDiscount.automaticDiscountType === 'DiscountAutomaticBxgy') {
        if (discount.automaticDiscount.customerBuys.items.collections) {
          discount.automaticDiscount.customerBuys.items.collections.nodes.forEach(
            (collectionNode) => {
              collectionNode.products.nodes.forEach((productNode) => {
                productNode.variants.nodes.forEach(variantNode => {
                  if (
                    allProductIdsInCart.find(
                      (productId) => productId === variantNode.id
                    )
                  ) {
                    matchedItems.push(variantNode)
                  }
                })
              })
            }
          )
        } else {
          discount.automaticDiscount.customerBuys.items.products.nodes.forEach(
            (node) => {
              node.variants.nodes.forEach((variantNode) => {
                if (
                  allProductIdsInCart.find(
                    (productId) => productId === variantNode.id
                  )
                ) {
                  matchedItems.push(variantNode)
                }
              })
            }
          )
        }

        if (
          discount.automaticDiscount.customerBuys.value.type === 'DiscountQuantity'
        ) {
          thresholdForDiscountMet =
            Number(discount.automaticDiscount.customerBuys.value.quantity) ===
            matchedItems.length
        } else if (
          discount.automaticDiscount.customerBuys.value.type ===
          'DiscountPurchaseAmount'
        ) {
          const costTotalOfAllParts = matchedItems.reduce((acc, curr) => {
            acc += Number(curr.price)
            return acc
          }, 0)

          thresholdForDiscountMet =
            costTotalOfAllParts >=
            Number(discount.automaticDiscount.customerBuys.value.amount)
        }

        return {
          matchedItems,
          thresholdForDiscountMet,
          discountSummary: buildDiscountSummary(
            discount.automaticDiscount.customerGets
          ),
          customerGets: buildFreeGiftOptions(
            discount.automaticDiscount.customerGets
          ),
        }
      }
    } else if ('codeDiscount' in discount) {
      if (discount.codeDiscount.automaticDiscountType === 'DiscountCodeBasic') {
        return {
          matchedItems,
          thresholdForDiscountMet,
          codeDetails: {
            autoApply: true,
            code: discount.codeDiscount.codes?.nodes?.[0].code
          },
          discountSummary: buildDiscountSummary(
            discount.codeDiscount.customerGets
          ),
          customerGets: buildFreeGiftOptions(
            discount.codeDiscount.customerGets
          ),
        }
      }
    }
  })?.sort((a, b) => {
    if (a.discountSummary.discountIsFree || b.discountSummary.discountIsFree) {
      return -1
    }

    return 1
  })

  /**
   * If a customised product or static product was passed in then
   * only return discounts that are applicable to the customised product that was passed in
   * by assessing the sku exists in the items inside the customer gets array.
   *
   * The customisedProduct in this case is the product that is being assessed for applicable
   * discounts.
   *
   * In all other cases returns all the discounts available based on the cart items.
   *
   * */

  return builtDiscounts.filter(Boolean).filter(builtDiscount => {
    if (staticProductData?.sku || customisedProduct?.sku) {
      return builtDiscount?.customerGets.filter(customerGetsItem => {
        return customerGetsItem.options.filter(option => {
          return option.sku === (staticProductData?.sku || customisedProduct?.sku)
        }).length
      }).length
    }

    return true
  })
}

export function getAllDiscountsFromProduct(
  customisedProduct: any | null,
  allValidGiftDiscounts: Array<DiscountAutomaticBasic | DiscountAutomaticBxgy>,
  staticProductData?: {
    sku: string,
    price: number
  }
): DiscountsForProduct | object {
  /**
   *
   * Discounts for products can be free gifts, percentage of other products or even a percentage of
   * their own product.
   *
   * This function returns the qualifying product discounts/gifts for a customised product.
   *
   * Each discount can have a qualifying list of product parts that a customer must purchase to qualify
   * for the free gift.
   *
   *
   * =======================================================================================================
   * Breakdown
   * =======================================================================================================
   *
   *
   * 1. Filter discounts to get only automatic discounts with discountClass set to "PRODUCT" and that have a
   * customerGets value which has products or productVariants in the items array and customerBuys list has a
   * set of product options that trigger the discount. This is stored in allValidGiftDiscounts.
   *
   *
   *
   * 2. Search through the customerBuys.items to check if any of the current customised product parts
   * exist in that array from Shopify. The items can be defined as either part of a collection ("DiscountCollection") or as
   * part of a list of products ("DiscountProducts") that is manually defined per specific discount.
   *
   * The items in the customerBuys array must also pass the thresholds set by the discount:
   *
   * If the discount has a price threshold then the price of the customised product must be over that discount price.
   *
   * If the discount has a quantity threshold then the total quantity of any items in the current customised product
   * that match with any items in the customerBuys must be totaled and checked against the threshold for the discount.
   *
   *
   *
   * 3. If we found matching parts and the current selections in customised product pass the discount thresholds
   * then return the products and product variants a customers "gets" as an array of useful and easy to access
   * information that can be consumed within pages and components.
   */

  const groupedDiscounts = allValidGiftDiscounts
    .map((validGiftDiscount) => {
      const matchedItems: Array<{
        id: string
        sku: string
        price: string
      }> = []

      const newMatchedItem = getQualifyingProductForDiscount(
        validGiftDiscount,
        customisedProduct,
        'customerBuys',
        staticProductData
      )

      if (newMatchedItem) {
        matchedItems.push(newMatchedItem)
      }

      /**
       * If there are no matched items then there is no discount applicable to the customer
       * selected product or any of it's parts.
       */
      if (!matchedItems.length) return {}

      let thresholdForDiscountMet = false

      if (validGiftDiscount.automaticDiscount.automaticDiscountType === 'DiscountAutomaticBxgy') {
      /**
         * Check sum matches or passes purchase quantity threshold or total price threshold
         * matches or passes based on the total price of the matched items.
         */
        if (
          validGiftDiscount.automaticDiscount.customerBuys.value.type ===
          'DiscountQuantity'
        ) {
          thresholdForDiscountMet =
            Number(
              validGiftDiscount.automaticDiscount.customerBuys.value.quantity
            ) === matchedItems.length
        } else if (
          validGiftDiscount.automaticDiscount.customerBuys.value.type ===
          'DiscountPurchaseAmount'
        ) {
          const costTotalOfAllParts = matchedItems.reduce((acc, curr) => {
            acc += Number(curr.price)
            return acc
          }, 0)

          thresholdForDiscountMet =
            costTotalOfAllParts >=
            Number(validGiftDiscount.automaticDiscount.customerBuys.value.amount)
        }

        return {
          matchedItems,
          thresholdForDiscountMet,
          thresholdSummary: {
            type: validGiftDiscount.automaticDiscount.customerBuys.value.type,
            amount:
              Number(validGiftDiscount.automaticDiscount.customerBuys.value.amount || validGiftDiscount.automaticDiscount.customerBuys.value.quantity),
            thresholdForDiscountMet
          },
          discountSummary: {
            ...buildDiscountSummary(validGiftDiscount.automaticDiscount.customerGets),
            specialTheme: validGiftDiscount.discountSummary?.specialTheme,
            tagText: validGiftDiscount.discountSummary?.tagText || '',
          },
          customerGets: buildFreeGiftOptions(
            validGiftDiscount.automaticDiscount.customerGets
          ),
        }
      } else if (validGiftDiscount.automaticDiscount.automaticDiscountType === 'DiscountAutomaticBasic') {
        if (validGiftDiscount.automaticDiscount.minimumRequirement?.greaterThanOrEqualToSubtotal?.amount) {
          const customisedProductPrice = staticProductData?.price || customisedProduct?.product?.priceGBP || customisedProduct?.priceGBP

          if (customisedProductPrice >= validGiftDiscount.automaticDiscount.minimumRequirement.greaterThanOrEqualToSubtotal.amount) {
            return {
              matchedItems,
              thresholdForDiscountMet: true,
              thresholdSummary: {
                type: validGiftDiscount.automaticDiscount.minimumRequirement.type,
                amount: validGiftDiscount.automaticDiscount.minimumRequirement.greaterThanOrEqualToSubtotal.amount,
                thresholdForDiscountMet: true
              },
              discountSummary: {
                ...buildDiscountSummary(validGiftDiscount.automaticDiscount.customerGets),
                specialTheme: validGiftDiscount.discountSummary?.specialTheme,
                tagText: validGiftDiscount.discountSummary?.tagText || '',
              },
              customerGets: buildFreeGiftOptions(
                validGiftDiscount.automaticDiscount.customerGets
              ),
            }
          }
        }
      } else {
        return {}
      }
    })
    .filter((a) => Object.keys(a).length)

  if (!groupedDiscounts.length) return {}

  return {
    groupedDiscounts,
    allDiscountOptions: getAllDiscountOptions(groupedDiscounts),
    anyDiscountThresholdPassed: !!groupedDiscounts.find(
      (availableDiscount) => availableDiscount?.thresholdForDiscountMet
    ),
    anyDiscountsAreFreeAndOverThreshold: !!groupedDiscounts.find(
      (availableDiscount) => availableDiscount?.discountSummary?.discountIsFree && availableDiscount?.thresholdForDiscountMet
    ),
    anyDiscountIsFree: !!groupedDiscounts.find(
      (availableDiscount) => availableDiscount?.discountSummary?.discountIsFree
    ),
  }
}

export function getUpdateProductQuantityPayload(
  bundles: Bundle[],
  incrementBy = 1
) {
  return bundles.flatMap((bundle: any) => {
    return bundle.isShopifyBundle
      ? [
          {
            id: bundle.bundleId,
            quantity: bundle.summary.bundleQuantity + incrementBy,
          },
        ]
      : bundle.items.map((item: any) => {
          return {
            id: item.node.id,
            merchandiseId: item.node.merchandise.id,
            quantity: bundle.summary.bundleQuantity + incrementBy,
          }
        })
  })
}


export function buildQtyChangePayload(bundle: Bundle, newQuantity: number) {
  return bundle.isShopifyBundle
    ? [
        {
          id: bundle.bundleId,
          quantity: newQuantity,
        },
      ]
    : bundle.items.map((item) => {
        return {
          id: item.node.id,
          merchandiseId: item.node.merchandise.id,
          quantity: newQuantity,
        }
      })
}

export function getFreshStockQuantities(customisedProduct, freshStockResponse) {
  const quantities = []

  if (customisedProduct.isPrebuiltVariant) {
    const matchedProductQuantity = freshStockResponse.productVariants.nodes.find(productVariant => {
        return productVariant.id === customisedProduct?.matchingPrebuiltVariant.commerceId
    })?.sellableOnlineQuantity ?? customisedProduct.matchingPrebuiltVariant?.quantity

    quantities.push({ sku: customisedProduct.sku, quantity: matchedProductQuantity })
  } else if (Object.values(customisedProduct.choices).length) {
    quantities.push(...Object.values(customisedProduct.choices).map(choice => {
      const quantity = freshStockResponse.productVariants.nodes.find(productVariant => {
        return productVariant.id === choice?.commerceId
      })?.sellableOnlineQuantity ?? choice?.quantity

      return {
        sku: choice.sku,
        quantity
      }
    }))
  } else if (customisedProduct.isLimitedEdition) {
    const matchedFreshStockProduct = freshStockResponse.productVariants.nodes.find(productVariant => {
      return productVariant.id === customisedProduct?.commerceId
    })

    quantities.push({
      sku: matchedFreshStockProduct ? matchedFreshStockProduct.sku : customisedProduct?.sku,
      quantity: matchedFreshStockProduct ? matchedFreshStockProduct.sellableOnlineQuantity : customisedProduct?.product?.quantity
    })
  }

  // Add the main product quantity if its stock is tracked
  if (!customisedProduct.product.doNotTrackStock) {
    const matchedFreshStockProduct = freshStockResponse.productVariants.nodes.find(productVariant => {
      return productVariant.id === customisedProduct?.commerceId
    })

    quantities.push({
      sku: matchedFreshStockProduct ? matchedFreshStockProduct.sku : customisedProduct?.sku,
      quantity: matchedFreshStockProduct ? matchedFreshStockProduct.sellableOnlineQuantity : customisedProduct?.product?.quantity
    })
  }

  return quantities.sort((a, b) => a.quantity - b.quantity)
}

export function getDisqualifiedCouponCodes (automaticCodeDiscounts: AutomaticCodeDiscountWithQualifyingProducts[], bundledCartItems, cartAppliedDiscountCodes) {
  /**
   *
   * Check each automaticCodeDiscount to see if a matching based product is
   * in cart. If not return the code for removal.
   *
   **/
  const appliedDiscountCodesToCheck = automaticCodeDiscounts.filter(automaticCodeDiscount => {
    return automaticCodeDiscount.codeDiscount.codes.nodes.find(codeNode => cartAppliedDiscountCodes.find(cartAppliedDiscountCode => cartAppliedDiscountCode.code === codeNode.code))
  })

  const appliedDiscountsForRemoval = appliedDiscountCodesToCheck.filter(automaticCodeDiscount => {
    return !automaticCodeDiscount.qualifyingBaseProducts?.some(baseSku => {
      // find if any products in cart still qualify for the discount
      return Object.values(bundledCartItems).some(bundledCartItem => {
        return bundledCartItem.summary.bundleBaseSku === baseSku
      })
    })
  })

  return appliedDiscountsForRemoval.flatMap(codeForRemoval => {
    return codeForRemoval.codeDiscount.codes.nodes.map(codeNode => codeNode.code)
  })
}

export function getMissingCartCodesFromAvailableDiscounts(availableDiscounts, couponCodes: Array<string>) {
  const couponCodesInCart = couponCodes.map(couponDetail => couponDetail)

  return availableDiscounts.map(availableDiscount => {
    if (availableDiscount.codeDetails?.autoApply) {
      // If the code is in cart do nothing
      if(couponCodesInCart.includes(availableDiscount.codeDetails.code)) return

      // Else return the code as a code to add
      return availableDiscount.codeDetails.code
    }
  }).filter(Boolean)
}
