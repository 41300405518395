import MarkdownIt from "markdown-it";

export default defineNuxtPlugin(() => {
  const renderer = new MarkdownIt({
    breaks: true,
    html: true,
    xhtmlOut: true
  });

  return {
    provide: {
      md: renderer,
    },
  };
});
