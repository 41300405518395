<template>
  <NuxtLayout>
    <div
      class="ErrorPage global-wrapper"
    >
      <h1 class="ErrorPage__title">
        <span v-if="props.error?.statusCode === 404">404 Not found</span>
        <span v-else>An Error Occured</span>
      </h1>

      <p class="ErrorPage__desc">
        <span v-if="props.error?.statusCode === 404">
          This page may have moved or no longer exists.
        </span>
        <span v-else>The page you requested cannot be loaded.</span>
      </p>

      <VisionButton
        to="/"
        button-text="Return Home"
        background-colour="gradient"
      />

      <p
        v-if="props.error?.data"
        size="body"
        class="ErrorPage__event-id"
      >
        #{{ props.error?.data }}
      </p>
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import type { NuxtError } from '#app'

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  error: Object as () => NuxtError
})

useHead({
  bodyAttrs: {
    class: 'is-error'
  }
})
</script>

<style lang="postcss">
.ErrorPage {
  @apply my-md;
}

.ErrorPage__title {
  @apply font-bold text-lg mb-2;
}

.ErrorPage__desc {
  @apply text-sm mt-0 mb-6;
}

.ErrorPage__event-id {
  @apply text-xs text-gray-500
    font-mono
    select-all;
}

.is-explore-theme .ErrorPage {
  @apply mt-[var(--nav-height--mobile)] md:mt-[var(--nav-height--desktop)] pt-12;
}
</style>
