
const descriptions = {
  ff: 'for complete protection and maximum coverage, best for road cycling and mountain biking.',
  tf: 'for maximum peripheral vision and an unobstructed view of the road, best for road cycling, mountain biking and running.',
  bf: 'provides unobstructed peripheral vision and ultimate protection, best for road cycling and mountain biking.',
  zf: 'for a completely unobstructed peripheral and vertical field-of-view, best for road cycling and running.',
  ckTFFF: '<b>One pair, two styles.</b> A Conversion Kit lets you swap between Top Frame and Full Frame styles, at home or on the go.',
  ckBFZF: '<b>One pair, two styles.</b> A Conversion Kit lets you swap between Top Frame and Full Frame styles, at home or on the go.',
  ckLimitedEditionTFFF: '<b>One pair, two styles.</b> This limited edition comes with a Conversion Kit lets you swap between Top Frame and Full Frame styles.'
}

const frameTypes = {
  airas: [
    {
      frameType: 'zf',
      title: 'Zero Frame',
      parts: ['cn'],
      description: `<b>Airas™ Zero Frame</b> ${descriptions.zf}`,
      defaultForLRK: true
    },
    {
      frameType: 'bf',
      title: 'Base Frame',
      badge: 'Bestseller',
      badgeColor: 'blue',
      parts: ['cb'],
      description: `<b>Airas™ Base Frame</b> ${descriptions.bf}`
    },
    {
      conversionKit: true,
      title: 'Zero & Base',
      description: descriptions.ckBFZF
    }
  ],
  vulcans: [
    {
      frameType: 'tf',
      title: 'Top Frame',
      parts: ['cn'],
      description: `<b>Vulcans™ Top Frame</b> ${descriptions.tf}`,
      defaultForLRK: true
    },
    {
      frameType: 'ff',
      title: 'Full Frame',
      badge: 'Bestseller',
      badgeColor: 'blue',
      parts: ['cb'],
      description: `<b>Vulcans™ Full Frame</b> ${descriptions.ff}`
    },
    {
      conversionKit: true,
      title: 'Top & Full',
      description: descriptions.ckTFFF,
      leDescription: descriptions.ckLimitedEditionTFFF
    }
  ],
  velans: [
    {
      frameType: 'tf',
      title: 'Top Frame',
      badge: 'Bestseller',
      badgeColor: 'blue',
      parts: ['sn'],
      description: `<b>Velans™ Top Frame</b> ${descriptions.tf}`,
      defaultForLRK: true
    },
    {
      frameType: 'ff',
      title: 'Full Frame',
      parts: ['sb'],
      description: `<b>Velans™ Full Frame</b> ${descriptions.ff}`
    },
    {
      conversionKit: true,
      title: 'Top & Full',
      description: descriptions.ckTFFF,
      leDescription: descriptions.ckLimitedEditionTFFF
    }
  ],
  ultras: [
    {
      frameType: 'zf',
      title: 'Zero Frame',
      description: `<b>Ultras™ Zero Frame</b> ${descriptions.zf}`
    }
  ],
  gts: [
    {
      frameType: 'ff',
      title: 'Full Frame',
      description: `<b>GTs™ Full Frame</b> ${descriptions.ff}`
    }
  ]
}

export default function getFrameTypes (baseSku) {
  return frameTypes[baseSku] || []
}